import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 2828.000000 2828.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} /> 
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,2828.000000) scale(0.100000,-0.100000)">
          <path d="M18881 19055 c7 -7 540 -7 547 -1 3 3 -120 6 -273 6 -153 0 -276 -2
-274 -5z"/>
<path d="M19381 19014 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M19412 18575 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M19386 18504 c-3 -9 -4 -20 0 -25 3 -5 0 -9 -5 -9 -6 0 -11 6 -11 13
0 9 -2 9 -8 0 -5 -7 -40 -13 -87 -16 -66 -2 -60 -3 35 -5 84 -1 110 1 97 9
-12 7 -16 17 -12 30 8 23 0 26 -9 3z"/>
<path d="M19058 18463 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M15054 17118 c-3 -13 -48 -111 -100 -218 -51 -107 -96 -213 -99 -235
-8 -46 5 -84 83 -263 48 -108 50 -111 64 -90 8 13 17 31 21 40 3 9 32 63 64
120 78 137 112 216 125 287 10 51 9 66 -7 108 -28 75 -127 273 -136 273 -4 0
-11 -10 -15 -22z"/>
<path d="M18438 16743 c68 -2 176 -2 240 0 64 1 9 3 -123 3 -132 0 -185 -2
-117 -3z"/>
<path d="M18698 16723 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M18212 16423 l-2 -273 303 1 c166 1 289 5 272 9 l-30 8 28 1 27 1 -2
263 -3 262 -2 -247 c-2 -148 -7 -248 -13 -248 -6 0 -7 32 -3 80 4 54 2 80 -5
80 -6 0 -12 -6 -15 -12 -2 -7 -8 -9 -13 -5 -5 4 -2 12 5 17 15 8 13 41 -8 150
-6 30 -14 82 -18 114 -5 35 -13 61 -21 62 -7 1 -8 0 -2 -3 5 -2 10 -18 10 -34
0 -29 -1 -29 -56 -29 -47 0 -57 3 -61 20 -3 11 -11 20 -17 20 -19 0 -56 -30
-56 -46 0 -8 -10 -14 -25 -14 -22 0 -25 -4 -25 -39 0 -39 1 -40 38 -44 25 -3
22 -4 -10 -2 -54 2 -68 -7 -68 -47 0 -21 -5 -28 -20 -28 -15 0 -20 -7 -20 -25
0 -22 4 -25 40 -25 36 0 40 3 40 25 0 16 6 25 15 25 16 0 19 -20 9 -57 -6 -22
-12 -23 -94 -23 l-88 0 -4 -50 c-1 -28 0 -59 3 -68 6 -16 7 -16 14 1 8 19 65
25 65 6 0 -6 -7 -9 -15 -5 -12 4 -14 0 -9 -19 5 -21 3 -25 -17 -26 -43 -2 51
-19 151 -28 87 -7 78 -8 -97 -9 l-193 -2 -3 268 -2 267 -3 -272z m355 185 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m115 -100 c-9 -9 -15 -9 -24
0 -9 9 -7 12 12 12 19 0 21 -3 12 -12z m-242 -268 l0 -40 63 -2 c59 -2 60 -2
13 -5 -81 -7 -96 1 -96 47 0 22 5 40 10 40 6 0 10 -18 10 -40z m248 -77 c-10
-2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z"/>
<path d="M18581 16686 c12 -10 59 -15 59 -6 0 5 -14 10 -32 10 -17 0 -29 -2
-27 -4z"/>
<path d="M13225 16485 c-99 -7 -279 -18 -400 -24 -121 -7 -236 -15 -255 -19
-41 -9 -20 72 -215 -834 -19 -90 -39 -181 -45 -203 -5 -22 -21 -93 -35 -157
-28 -134 -59 -272 -109 -503 -19 -88 -51 -234 -71 -325 -20 -91 -53 -244 -75
-340 -54 -244 -57 -267 -33 -274 105 -32 1055 -268 1059 -263 3 3 33 115 99
372 82 321 86 334 96 308 12 -33 44 -218 89 -518 12 -77 28 -178 36 -224 8
-46 14 -92 14 -103 0 -10 7 -35 16 -56 l16 -37 41 46 c23 25 134 146 246 270
113 123 234 256 270 294 36 39 106 115 156 170 121 135 214 229 220 223 3 -3
-4 -77 -15 -164 -21 -156 -38 -297 -59 -464 -6 -47 -18 -134 -26 -195 -33
-246 -33 -246 -18 -252 8 -3 42 -3 76 0 165 17 259 27 332 37 44 5 154 19 245
30 91 12 206 27 255 35 50 7 142 21 205 30 63 10 125 21 136 25 l22 8 6 709
c4 389 11 935 16 1213 14 704 13 910 -2 914 -7 2 -105 11 -216 21 -117 9 -222
23 -245 32 -53 19 -80 23 -88 10 -4 -6 -74 -5 -190 3 -368 24 -332 24 -352 -5
-49 -69 -303 -521 -420 -745 -58 -112 -91 -160 -102 -148 -3 3 -61 189 -129
414 -69 225 -144 474 -169 554 l-44 145 -79 1 c-44 1 -160 -4 -259 -11z"/>
<path d="M18280 16290 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8
0 -15 -4 -15 -10z"/>
<path d="M18585 15510 c-134 -9 -205 -19 -205 -31 0 -5 -6 -6 -13 -3 -30 11
-164 -60 -149 -79 4 -7 9 -22 10 -35 1 -20 -1 -21 -14 -7 -9 8 -20 15 -26 15
-6 0 -5 7 3 18 10 14 9 13 -7 -2 -13 -13 -15 -20 -6 -23 19 -7 14 -23 -14 -45
-14 -12 -23 -27 -20 -35 3 -8 1 -11 -4 -8 -17 10 -11 27 18 52 19 16 21 22 8
17 -13 -5 -17 -4 -12 4 13 21 -10 13 -30 -10 -10 -13 -12 -17 -4 -10 20 17 35
15 20 -3 -7 -8 -18 -15 -25 -15 -7 0 -19 -8 -26 -17 -11 -15 -11 -16 1 -5 21
18 35 14 18 -5 -9 -10 -14 -23 -11 -29 2 -6 -4 -11 -12 -11 -9 0 -14 6 -11 14
8 20 -2 16 -14 -6 -7 -14 -7 -22 2 -27 7 -5 8 -3 3 7 -5 8 -4 11 0 6 13 -11
14 -49 3 -71 -9 -16 -7 -18 6 -13 9 4 16 3 16 -2 0 -16 -14 -20 -28 -8 -13 10
-15 9 -9 -12 4 -18 3 -21 -6 -12 -9 9 -8 19 5 42 17 29 15 59 -3 59 -13 0 -44
-74 -57 -137 -7 -29 -14 -123 -17 -210 -9 -265 -15 -373 -30 -505 -38 -331
-122 -676 -241 -989 -26 -68 -42 -125 -38 -135 4 -15 4 -16 -4 -5 -8 11 -11 9
-17 -8 -3 -11 -3 -26 1 -32 4 -7 3 -9 -3 -6 -6 4 -16 -8 -23 -26 -49 -125
-260 -514 -356 -656 -13 -19 -20 -39 -17 -44 3 -6 1 -7 -5 -3 -11 7 -17 -6
-13 -27 1 -5 -3 -6 -8 -3 -12 8 -63 -72 -55 -86 4 -6 3 -8 -4 -4 -13 8 -44
-33 -35 -47 3 -6 1 -7 -4 -4 -13 8 -95 -98 -98 -127 -4 -33 -4 -33 -22 -40
-15 -6 -15 -4 2 14 10 11 16 22 13 25 -2 3 -25 -20 -51 -49 -46 -54 -58 -74
-35 -60 7 4 8 3 4 -5 -4 -6 -11 -9 -15 -6 -10 6 -157 -155 -153 -168 1 -5 -1
-6 -6 -3 -12 7 -84 -68 -75 -78 5 -4 3 -6 -3 -4 -6 2 -36 -20 -68 -49 l-57
-52 30 -15 c16 -9 33 -22 37 -28 6 -10 4 -11 -7 0 -16 15 -33 16 -23 0 4 -7 3
-8 -5 -4 -6 4 -9 11 -6 16 3 5 -4 12 -16 15 -21 7 -27 3 -109 -75 -19 -18 -28
-28 -19 -22 9 7 20 8 24 4 5 -4 5 -1 0 7 -5 9 -4 12 3 7 6 -3 15 2 21 11 6 10
11 13 11 7 0 -6 -9 -19 -20 -29 -11 -10 -20 -15 -20 -10 0 4 -6 2 -13 -4 -8
-6 -16 -8 -20 -5 -10 10 -132 -98 -133 -118 -1 -13 4 -17 13 -14 8 3 11 2 8
-3 -6 -11 -45 -10 -45 0 0 4 6 10 13 12 9 4 9 6 -1 6 -8 1 -39 -19 -70 -42
-31 -24 -96 -70 -144 -102 -48 -32 -85 -62 -81 -68 3 -5 1 -7 -4 -4 -16 10
-153 -72 -147 -88 7 -17 -15 -30 -28 -17 -7 7 -21 4 -44 -10 -39 -23 -42 -27
-15 -25 11 0 18 4 16 7 -2 4 13 6 34 6 21 -1 38 -6 38 -12 0 -7 -18 -10 -44
-9 -34 2 -47 -1 -56 -16 -6 -10 -19 -19 -27 -19 -24 -1 -51 -25 -39 -37 6 -6
5 -10 -6 -10 -20 0 -19 24 1 35 15 8 15 10 0 15 -12 5 -15 3 -10 -5 5 -7 2 -9
-7 -6 -8 3 -30 -2 -48 -11 -30 -16 -31 -18 -15 -32 16 -12 17 -12 11 4 -3 9
-2 17 4 17 16 0 22 -28 8 -43 -12 -12 -16 -12 -27 2 -12 14 -13 14 -9 -5 6
-30 -18 -60 -36 -46 -8 7 -17 8 -21 4 -4 -4 -6 7 -3 25 5 30 4 31 -19 21 -13
-6 -21 -16 -17 -21 4 -6 0 -8 -11 -4 -10 4 -15 3 -11 -4 4 -5 16 -7 28 -3 20
6 21 5 7 -11 -14 -17 -13 -18 3 -12 24 9 33 -18 13 -38 -9 -9 -16 -23 -16 -31
0 -8 -4 -14 -10 -14 -5 0 -12 -16 -16 -36 -4 -19 -13 -38 -20 -41 -8 -3 -14
-20 -14 -40 0 -19 -6 -49 -14 -66 -8 -18 -17 -44 -20 -59 -4 -16 -13 -28 -20
-28 -8 0 -16 -10 -20 -23 -3 -13 -21 -40 -41 -60 -19 -20 -35 -45 -35 -56 0
-10 -6 -22 -12 -24 -10 -4 -10 -6 0 -6 6 -1 12 -10 12 -21 0 -17 -7 -20 -40
-20 -32 0 -40 -4 -40 -18 0 -9 -9 -23 -20 -30 -15 -9 -20 -23 -20 -53 0 -24
-4 -38 -10 -34 -5 3 -10 -3 -10 -14 0 -11 -4 -23 -10 -26 -5 -3 -10 -1 -10 4
0 6 -9 11 -20 11 -15 0 -20 -7 -20 -29 0 -24 -3 -28 -20 -24 -14 4 -20 0 -20
-11 0 -9 -8 -16 -19 -16 -16 0 -21 8 -24 43 l-4 42 -1 -40 c-2 -33 -7 -42 -29
-52 -16 -7 -42 -25 -59 -40 -16 -16 -47 -39 -67 -53 -38 -26 -53 -69 -19 -55
9 4 27 10 38 12 14 3 24 15 27 31 4 18 16 32 36 40 27 12 33 11 47 -3 15 -15
14 -15 -11 -9 -25 6 -27 5 -21 -12 4 -10 10 -31 13 -46 3 -17 12 -28 22 -27
10 0 11 2 4 6 -7 2 -13 11 -13 20 0 12 2 12 16 -1 8 -9 23 -16 32 -15 12 0 13
2 3 6 -15 6 -51 40 -51 49 0 3 5 2 10 -1 6 -3 20 -3 31 0 17 6 19 9 8 17 -10
7 -8 7 9 3 17 -5 22 -2 23 12 0 12 2 14 6 4 3 -7 -6 -23 -21 -36 l-25 -22 24
-13 c14 -7 28 -10 32 -6 4 4 -3 9 -15 13 -21 5 -21 6 -5 19 10 7 30 14 45 14
15 0 30 5 33 10 3 6 14 10 23 9 14 0 13 -2 -3 -9 -11 -5 -14 -9 -7 -9 7 -1 11
-5 8 -10 -3 -5 -24 -7 -46 -4 -23 3 -40 2 -40 -4 0 -6 12 -9 28 -6 23 3 24 2
7 -6 -11 -5 -14 -10 -7 -10 21 -1 267 81 270 90 2 5 7 7 12 4 10 -6 101 25 96
33 -2 4 8 7 22 8 15 1 62 16 105 34 58 24 75 35 65 42 -9 7 -8 8 6 4 22 -7
128 33 120 46 -3 4 3 5 12 1 11 -4 15 -2 11 4 -4 6 -1 11 6 10 34 -1 48 4 41
15 -4 6 -3 8 4 4 6 -3 31 3 55 15 34 16 44 27 42 41 -2 10 0 16 6 13 5 -4 7
-11 4 -16 -10 -15 0 -11 79 28 41 20 72 41 69 46 -3 6 -1 7 5 3 12 -7 49 13
50 27 0 5 4 12 8 15 4 4 4 1 0 -5 -11 -20 2 -16 71 23 38 21 61 40 57 46 -4 7
-2 8 4 4 15 -9 213 114 205 127 -3 6 -1 7 5 3 19 -12 410 275 404 296 -1 5 1
7 6 4 12 -7 94 63 87 75 -4 5 0 7 7 4 7 -3 33 14 58 37 25 23 86 80 135 126
50 46 150 148 223 226 723 767 1193 1758 1337 2815 34 254 45 462 45 878 l0
413 -57 -2 c-32 0 -107 -4 -168 -8z m-190 -50 c-3 -5 -14 -10 -23 -9 -14 0
-13 2 3 9 27 11 27 11 20 0z m-70 -10 c-3 -5 0 -10 7 -10 9 0 9 -3 -2 -10 -8
-5 -11 -10 -7 -10 5 0 5 -5 2 -10 -4 -6 -11 -8 -16 -4 -6 4 -6 11 2 20 12 15
2 18 -25 8 -9 -3 -13 -11 -9 -17 4 -6 0 -8 -9 -4 -9 3 -21 0 -27 -6 -6 -6 -13
-8 -16 -5 -3 3 18 17 46 32 59 29 63 30 54 16z m65 -20 c-8 -5 -22 -9 -30 -9
-10 0 -8 3 5 9 27 12 43 12 25 0z m-261 -164 c10 -12 8 -13 -8 -9 -12 3 -18 9
-15 14 7 12 10 11 23 -5z m-95 -111 c-16 -30 -16 -34 -1 -49 8 -9 21 -16 28
-16 7 0 10 -3 6 -6 -9 -10 -45 6 -51 23 -4 9 1 36 11 61 9 25 19 40 21 33 2
-7 -4 -28 -14 -46z m-27 -87 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0
-25z m-10 -60 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-4 -388
c-3 -12 -8 -19 -11 -16 -5 6 5 36 12 36 2 0 2 -9 -1 -20z m-7 -67 c-11 -11
-17 4 -9 23 7 18 8 18 11 1 2 -10 1 -21 -2 -24z m-16 -150 c-11 -17 -11 -17
-6 0 3 10 6 24 7 30 0 9 2 9 5 0 3 -7 0 -20 -6 -30z m-13 -85 c-3 -8 -6 -5 -6
6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-456 -1435 c-12 -20 -14 -14 -5 12 4 9
9 14 11 11 3 -2 0 -13 -6 -23z m-28 -58 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15
9 16 15 13 8 -4z m-309 -503 c3 -5 -1 -9 -9 -9 -8 0 -12 4 -9 9 3 4 7 8 9 8 2
0 6 -4 9 -8z m-52 -86 c7 4 8 2 4 -5 -9 -13 -26 -5 -25 12 0 9 2 8 6 -1 2 -6
10 -9 15 -6z m-242 -297 c-6 -10 -13 -19 -16 -19 -2 0 0 8 5 19 6 10 7 22 3
26 -4 5 -1 5 6 1 9 -5 9 -12 2 -27z m-20 7 c0 -2 -8 -10 -17 -17 -16 -13 -17
-12 -4 4 13 16 21 21 21 13z m960 -10 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12
-4 4 13 16 21 21 21 13z m-1000 -20 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12 -4
4 13 16 21 21 21 13z m-56 -74 c2 -4 -4 -8 -14 -8 -10 0 -16 4 -14 8 3 4 9 8
14 8 5 0 11 -4 14 -8z m-64 -66 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10
25 14 25 6z m-34 -28 c4 -6 3 -8 -3 -5 -15 9 -36 -19 -27 -35 5 -10 4 -10 -6
-1 -11 10 -10 16 5 33 21 23 22 24 31 8z m-45 -55 c11 -15 11 -16 0 -6 -7 6
-21 9 -30 6 -14 -4 -14 -3 -1 6 8 6 16 11 16 11 1 0 8 -8 15 -17z m-55 -35 c4
-7 3 -8 -4 -4 -7 4 -12 1 -12 -9 0 -9 -5 -13 -10 -10 -8 5 -7 11 1 21 14 17
16 17 25 2z m-161 -138 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16
10 6 0 7 -4 4 -10z m-37 -26 c-1 -5 -9 -9 -16 -10 -10 -2 -11 1 -1 12 12 14
24 13 17 -2z m-127 -111 c6 -35 5 -54 -4 -60 -6 -4 -7 -1 -2 7 6 10 4 12 -9 7
-9 -4 -15 -3 -11 2 3 5 -1 12 -7 15 -7 3 -3 3 10 0 24 -6 29 5 10 24 -9 9 -9
12 0 12 7 0 13 -3 13 -7z m-38 -10 c-7 -2 -13 -11 -13 -19 0 -8 -8 -14 -18
-14 -10 0 -24 -6 -32 -12 -12 -11 -12 -10 -1 5 7 9 19 17 26 17 7 0 15 7 19
15 3 8 11 15 18 14 10 0 10 -2 1 -6z m-102 -89 c-12 -9 -19 -20 -15 -25 3 -6
-5 -8 -20 -5 -13 2 -27 -1 -29 -7 -2 -8 -5 -8 -10 0 -4 6 5 19 19 28 15 9 23
12 19 5 -5 -8 -3 -11 6 -8 8 3 13 10 12 15 -1 5 8 10 19 10 21 2 21 2 -1 -13z
m-121 -74 c-13 -8 -12 -10 4 -10 11 0 17 -2 14 -5 -3 -4 -18 -3 -33 1 -20 5
-23 9 -13 15 7 5 20 9 28 9 13 0 13 -1 0 -10z m-20 -27 c0 -5 -13 -8 -29 -8
-16 0 -27 3 -24 8 2 4 15 7 29 7 13 0 24 -3 24 -7z m-82 -27 c-7 -8 -17 -12
-22 -9 -4 3 -2 9 5 14 22 13 31 11 17 -5z m-68 -36 c0 -5 -5 -10 -11 -10 -5 0
-7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-163 -100 c1 -16 -1 -17 -12 -7
-11 10 -8 19 10 27 1 0 1 -9 2 -20z m73 -10 c0 -5 -4 -10 -10 -10 -5 0 -10 5
-10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-115 -79 c-3 -6 -11 -11 -17 -11 -6
0 -6 6 2 15 14 17 26 13 15 -4z m-75 -106 c0 -8 -2 -15 -4 -15 -2 0 -6 7 -10
15 -3 8 -1 15 4 15 6 0 10 -7 10 -15z m199 -598 c-2 -1 -13 -9 -24 -17 -19
-14 -19 -14 -6 3 7 9 18 17 24 17 6 0 8 -1 6 -3z m-549 -111 c0 -3 -4 -8 -10
-11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m200 -27 c12 -8 11
-9 -6 -4 -14 3 -28 -1 -38 -10 -9 -9 -26 -17 -38 -17 -13 -1 -29 -6 -37 -12
-11 -9 -13 -8 -9 4 2 8 17 15 32 16 15 1 35 8 44 18 19 19 31 20 52 5z m-195
-69 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z"/>
<path d="M18205 15380 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z"/>
<path d="M16911 15304 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M16943 14875 c0 -44 2 -61 4 -37 2 23 2 59 0 80 -2 20 -4 1 -4 -43z"/>
<path d="M16932 14605 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M16922 14505 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M16911 14424 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M16901 14354 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M16892 14300 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M16826 14025 c-3 -9 -6 -22 -5 -28 0 -7 5 -1 10 12 5 13 8 26 5 28
-2 2 -6 -3 -10 -12z"/>
<path d="M16811 13964 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M16781 13864 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M16766 13825 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11
-11z"/>
<path d="M16736 13745 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11
-11z"/>
<path d="M16640 13695 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M16706 13665 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11
-11z"/>
<path d="M16675 13593 c-8 -20 -13 -38 -10 -41 3 -3 5 -3 5 0 0 3 7 22 15 42
8 20 13 36 10 36 -2 0 -12 -16 -20 -37z"/>
<path d="M16635 13500 c-15 -34 -20 -38 -33 -27 -13 11 -14 9 -1 -10 14 -23
14 -22 37 23 13 25 22 48 19 50 -2 2 -12 -14 -22 -36z"/>
<path d="M16579 13388 c-12 -24 -33 -63 -47 -88 -14 -25 -20 -40 -14 -35 14
13 95 165 88 165 -3 0 -15 -19 -27 -42z"/>
<path d="M16490 13230 c-6 -11 -8 -20 -6 -20 3 0 10 9 16 20 6 11 8 20 6 20
-3 0 -10 -9 -16 -20z"/>
<path d="M16464 13185 c-4 -8 -10 -13 -14 -10 -4 3 -7 -3 -6 -13 1 -11 -6 -16
-18 -15 -12 1 -15 -2 -8 -9 7 -7 -5 -33 -40 -84 -28 -41 -55 -74 -60 -74 -5 0
-7 4 -4 8 2 4 -4 9 -15 9 -11 1 -17 -2 -14 -6 2 -5 -4 -11 -15 -14 -11 -3 -20
-11 -20 -19 0 -10 2 -10 9 1 10 15 41 8 41 -9 0 -6 -5 -8 -12 -4 -7 4 -8 3 -4
-5 4 -6 4 -18 -1 -24 -4 -7 2 -2 14 12 12 14 20 28 17 32 -2 4 2 9 9 12 20 8
167 227 152 227 -3 0 -8 -7 -11 -15z"/>
<path d="M16355 13069 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M16249 12873 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8
-17 -17z"/>
<path d="M16189 12803 l-24 -28 28 24 c25 23 32 31 24 31 -2 0 -14 -12 -28
-27z"/>
<path d="M16155 12760 c-4 -6 -9 -9 -13 -6 -4 2 -16 0 -26 -4 -19 -6 -19 -7 1
-12 14 -4 28 1 39 13 9 10 14 19 11 19 -3 0 -9 -5 -12 -10z"/>
<path d="M16096 12718 c3 -5 10 -6 15 -3 13 9 11 12 -6 12 -8 0 -12 -4 -9 -9z"/>
<path d="M16085 12680 c-9 -10 -21 -18 -25 -17 -4 1 -9 -2 -10 -8 -1 -5 -6
-16 -12 -23 -9 -10 -14 -10 -27 0 -14 11 -14 11 -4 -3 10 -13 6 -22 -25 -52
-27 -26 -41 -34 -50 -27 -9 7 -9 7 -2 -2 7 -8 4 -18 -7 -32 -10 -12 29 24 85
79 56 55 102 101 102 102 0 7 -8 1 -25 -17z"/>
<path d="M15885 12509 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M15849 12453 l-24 -28 28 24 c15 14 27 26 27 28 0 8 -8 1 -31 -24z"/>
<path d="M15781 12400 c-13 -11 -19 -20 -14 -20 6 0 18 9 28 20 23 25 17 25
-14 0z"/>
<path d="M15703 12343 c0 -8 6 -9 19 -2 10 5 18 11 18 12 0 2 -8 3 -18 3 -10
0 -19 -6 -19 -13z"/>
<path d="M15650 12300 c-7 -12 -18 -19 -26 -16 -7 3 -13 1 -11 -4 1 -5 -11
-21 -28 -36 -21 -18 -16 -16 18 7 53 36 73 57 64 65 -3 3 -11 -4 -17 -16z"/>
<path d="M15526 12208 c3 -5 10 -6 15 -3 13 9 11 12 -6 12 -8 0 -12 -4 -9 -9z"/>
<path d="M15470 12169 c-22 -18 -22 -19 -3 -10 12 6 25 16 28 21 9 15 3 12
-25 -11z"/>
<path d="M15405 12130 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M15368 12123 c10 -4 9 -9 -5 -20 -17 -13 -16 -13 5 -3 22 11 22 31
-1 29 -8 0 -8 -2 1 -6z"/>
<path d="M15235 12030 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M15135 11980 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M15540 11000 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M15540 10960 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M15400 10934 c-16 -10 -18 -13 -5 -8 11 4 28 7 37 8 9 0 14 4 12 8
-7 11 -15 10 -44 -8z"/>
<path d="M15397 10896 c-16 -14 -36 -22 -45 -20 -15 5 -15 4 1 -6 9 -6 17 -15
17 -20 0 -6 -8 -10 -17 -10 -15 0 -16 2 -3 10 11 7 11 10 2 10 -7 0 -18 -13
-23 -30 -6 -16 -19 -32 -30 -35 -10 -4 -19 -13 -19 -21 0 -8 -4 -14 -10 -14
-5 0 -10 -18 -10 -40 0 -22 5 -40 10 -40 6 0 10 -18 10 -40 0 -36 -2 -40 -25
-40 -20 0 -25 -5 -25 -25 0 -20 5 -25 24 -25 13 0 27 -6 29 -12 3 -7 3 2 0 20
-5 26 -1 36 16 48 13 10 21 26 21 45 0 30 23 41 34 17 5 -10 7 -10 12 0 3 6
12 12 20 12 10 0 14 12 14 40 0 22 -4 40 -10 40 -5 0 -10 18 -10 40 0 22 5 40
10 40 6 0 10 6 8 14 -2 8 5 21 16 29 10 7 17 19 15 25 -3 8 -15 3 -32 -12z"/>
<path d="M15255 10876 c-25 -10 -26 -13 -12 -19 12 -5 16 -3 11 5 -5 7 -1 9
13 6 12 -3 20 0 20 8 0 8 -1 14 -1 14 -1 -1 -14 -7 -31 -14z"/>
<path d="M15431 10854 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M15167 10842 c-21 -10 -35 -21 -31 -25 12 -11 26 -8 18 4 -5 9 2 10
22 6 16 -3 23 -4 15 0 -10 4 -8 9 10 19 34 19 12 16 -34 -4z"/>
<path d="M15055 10800 c-13 -5 -14 -9 -5 -9 8 0 24 4 35 9 13 5 14 9 5 9 -8 0
-24 -4 -35 -9z"/>
<path d="M15030 10775 c14 -8 30 -14 35 -14 6 0 -1 6 -15 14 -14 8 -29 14 -35
14 -5 0 1 -6 15 -14z"/>
<path d="M15170 10775 c7 -8 18 -15 24 -15 6 0 5 4 -3 9 -11 7 -11 9 0 14 8 3
3 6 -10 6 -20 1 -21 -1 -11 -14z"/>
<path d="M14900 10750 c-18 -12 -2 -12 25 0 13 6 15 9 5 9 -8 0 -22 -4 -30 -9z"/>
<path d="M14818 10723 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M14578 10663 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M14518 10653 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M14358 10623 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M15180 10565 c0 -26 8 -43 35 -70 35 -34 53 -45 40 -25 -3 6 1 13 9
16 33 13 17 34 -24 34 l-40 0 0 40 c0 22 -4 40 -10 40 -5 0 -10 -16 -10 -35z"/>
<path d="M14008 10583 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M15120 10340 c0 -23 10 -26 28 -8 18 18 15 28 -8 28 -13 0 -20 -7
-20 -20z"/>
<path d="M14714 9946 c1 -8 5 -18 8 -22 4 -3 5 1 4 10 -1 8 -5 18 -8 22 -4 3
-5 -1 -4 -10z"/>
<path d="M14770 9956 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M14870 9950 c-13 -9 -13 -10 0 -11 8 -1 2 -5 -15 -9 -27 -7 -28 -8
-5 -8 14 0 41 6 60 14 l35 14 -35 -6 c-26 -4 -31 -3 -20 5 19 13 0 14 -20 1z"/>
<path d="M14725 9909 c-22 -11 -29 -17 -15 -14 14 4 36 7 50 7 14 0 19 3 12 5
-6 3 -10 9 -6 14 7 11 7 11 -41 -12z"/>
<path d="M14648 9913 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M14798 9913 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M14648 9883 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M14608 9873 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M14395 9860 c-10 -17 18 -21 75 -11 l55 10 -63 -5 c-44 -4 -62 -2
-57 5 3 6 4 11 1 11 -3 0 -8 -5 -11 -10z"/>
<path d="M14543 9863 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M14348 9833 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M14253 9823 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M14158 9813 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M14063 9803 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M13868 9793 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M11490 9016 c0 -15 -86 -752 -126 -1081 -14 -115 -28 -265 -31 -332
l-6 -123 236 0 237 0 2 328 c3 442 2 416 21 420 10 2 19 -2 22 -10 3 -7 31
-175 63 -373 l58 -360 202 -3 c111 -1 202 1 202 5 0 5 7 49 15 98 8 50 33 205
55 345 50 318 46 300 70 300 20 0 20 -8 20 -375 l0 -375 235 0 235 0 0 106 c0
58 -16 240 -35 403 -19 163 -51 436 -70 606 -20 171 -39 337 -43 370 l-7 60
-271 3 -270 2 -57 -297 c-31 -164 -57 -301 -57 -305 0 -16 -39 -7 -44 10 -3 9
-28 139 -56 287 -28 149 -53 278 -56 288 -5 16 -27 17 -275 17 -221 0 -269 -2
-269 -14z"/>
<path d="M13882 8838 c-137 -834 -182 -1142 -182 -1248 l0 -110 234 0 c129 0
237 4 240 8 2 4 10 74 17 155 l13 147 108 0 c104 0 108 -1 112 -22 5 -24 26
-242 26 -271 0 -16 21 -17 240 -17 l240 0 0 110 c0 110 -4 139 -175 1204 -19
120 -35 222 -35 227 0 5 -173 9 -403 9 l-404 0 -31 -192z m462 -210 c3 -13 12
-102 21 -198 8 -96 18 -192 21 -212 l6 -38 -77 0 -77 0 6 33 c3 17 13 118 21
222 9 105 18 196 21 203 2 6 15 12 28 12 18 0 27 -6 30 -22z"/>
<path d="M15734 9011 c-47 -22 -80 -59 -95 -110 -7 -23 -9 -120 -7 -266 4
-316 -12 -290 336 -520 l212 -140 0 -52 0 -53 -259 0 c-143 0 -266 -3 -275 -6
-14 -5 -16 -32 -16 -196 l0 -189 448 3 c505 3 498 2 550 81 l27 41 0 270 c0
258 -1 273 -22 311 -29 55 -38 62 -295 235 l-228 153 0 38 0 39 239 0 240 0
32 83 c29 71 34 97 37 190 l4 107 -444 0 c-410 0 -446 -2 -484 -19z"/>
<path d="M13714 6825 c-3 -8 -4 -162 -2 -342 3 -316 4 -330 26 -378 28 -61 55
-91 105 -116 34 -18 158 -39 230 -39 l27 0 0 105 0 105 -65 0 c-99 0 -105 9
-105 165 l0 125 84 0 84 0 4 78 c2 42 2 89 0 105 l-3 27 -85 0 -84 0 0 90 0
90 -105 0 c-84 0 -107 -3 -111 -15z"/>
<path d="M12970 6648 c0 -25 183 -585 203 -622 30 -53 66 -78 125 -84 63 -6
115 18 149 69 13 20 44 99 68 175 24 77 66 211 94 298 28 87 51 163 51 167 0
5 -49 9 -109 9 -96 0 -110 -2 -115 -17 -3 -10 -28 -106 -56 -213 -28 -107 -55
-205 -58 -218 -6 -17 -23 34 -66 195 -33 120 -63 226 -68 236 -7 14 -24 17
-113 17 -73 0 -105 -4 -105 -12z"/>
<path d="M14203 6644 c-13 -33 5 -437 21 -491 22 -69 98 -152 168 -184 67 -30
200 -32 262 -5 57 26 131 100 157 159 23 49 24 65 27 295 l3 242 -105 0 -106
0 0 -205 c0 -231 -7 -262 -60 -290 -41 -21 -89 -13 -121 21 -24 25 -24 29 -27
250 l-3 224 -105 0 c-87 0 -106 -3 -111 -16z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
